<template>
  <div class="gq-detail-background">
    <div class="gq-detail-body" ref="gq_detail">
      <div class="gq-detail-remove" @click="remove">
        <div></div>
      </div>
      <div class="gq-detail-img-box">
        <img :src="detailData.projectBackground" alt="" />
      </div>
      <div class="gq-detail-box">
        <!-- <div class="gq-detail-btn-box">
        <div
          class="gq-detail-btn-item"
          v-for="(item, i) in detailData.infoLabel"
          :key="i"
          :class="i != 0 ? 'left' : ''"
        >
          {{ item }}
        </div>
      </div> -->
        <div class="gq-detail-txt-box">
          <div class="gq-detail-name">
            {{ detailData.projectName }}
          </div>
          <!-- <div class="gq-detail-date">{{ detailData.createDate }}</div> -->
          <div class="gq-detail-txt" v-html="detailData.projectContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detailData: {},
    };
  },
  methods: {
    remove() {
      this.$emit("changeShow");
    },
    setData(data) {
      this.detailData = data;
    },
    toTop() {
      this.$nextTick(function() {
        this.$refs.gq_detail.scrollTop = 0
      })
    },
  },
  mounted() {
    // if (
    //   this.detailData.infoLabel &&
    //   !Array.isArray(this.detailData.infoLabel)
    // ) {
    //   this.detailData.infoLabel = this.detailData.infoLabel
    //     ? this.detailData.infoLabel.split(",")
    //     : [];
    // }
  },
};
</script>
<style lang="less" scoped>
.gq-detail-background {
  width: 100%;
  position: fixed;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}
.gq-detail-body {
  width: 1000px;
  height: 100%;
  z-index: 9999;
  background: #f2fcfe;
  position: fixed;
  z-index: 9999;
  overflow-y: scroll;
  // overflow-x: ;
  //   background: red;
  top: 0;
  bottom: 0;
  right: 0;
  .gq-detail-remove {
    position: fixed;
    top: 0;
    right: 1000px;
    margin-left: -113px;
    width: 113px;
    height: 113px;
    background: #80cad7;
    div {
      width: 90px;
      height: 90px;
      margin: 0 auto;
      margin-top: 30px;
      background: url("../assets/logos/remove.png") no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
  }
  .gq-detail-img-box {
    width: 1000px;
    height: 700px;
    img {
      width: 1000px;
      height: 700px;
    }
  }
  .gq-detail-box {
    margin-left: 74px;
    width: 730px;
    padding-top: 16px;
    img {
      max-width: 100%;
    }
    .gq-detail-btn-box {
      display: flex;
      text-align: center;
      padding-bottom: 12px;
      .left {
        margin-left: 14px;
      }
      .gq-detail-btn-item {
        width: 90px;
        height: 30px;
        border: 1px solid #ff9100;
        font-size: 18px;
        font-weight: 300;
        line-height: 30px;
        color: #ff9100;
      }
    }
    .gq-detail-name {
      font-size: 36px;
      font-weight: bold;
      color: #222222;
    }
    .gq-detail-date {
      font-size: 14px;
      padding-top: 16px;
      font-weight: normal;
      line-height: 16px;
      color: #222222;
    }
    .gq-detail-txt {
      font-size: 16px;
      font-family: Helvetica;
      font-weight: 300;
      padding-top: 38px;
      color: #222222;
      padding-bottom: 50px;
    }
  }
}
</style>