<template>
  <div class="gq-card-body">
    <div class="gq-pc-card">
      <div class="gq-card-img-box">
        <img :src="inforData.infoChart" alt="" @click="detailShow" />
      </div>
      <div class="gq-card-txt-box">
        <div class="gq-card-btn-box">
          <div
            class="gq-card-btn"
            v-for="(item, i) in inforData.infoLabel"
            :key="i"
            :class="i != 0 ? 'left' : ''"
          >
            {{ item }}
          </div>
        </div>
        <div class="gq-card-name" @click="detailShow">
          {{ inforData.infoTitle }}
        </div>
        <div class="gq-card-date">{{ inforData.createDate }}</div>
        <div class="gq-card-txt" v-text="inforData.infoBrief"></div>
      </div>
    </div>
    <div class="gq-phone-card">
      <div class="gq-card-txt-box">
        <div class="gq-card-name" @click="toDetail">
          {{ inforData.infoTitle }}
        </div>
        <div class="gq-card-date">{{ inforData.createDate }}</div>
        <div class="gq-card-btn-box">
          <div
            class="gq-card-btn"
            v-for="(item, i) in inforData.infoLabel"
            :key="i"
            :class="i != 0 ? 'left' : ''"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="gq-card-img-box">
        <img :src="inforData.infoChart" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    inforData: {
      type: Object,
    },
  },
  mounted() {
    if (!Array.isArray(this.inforData.infoLabel)) {
      this.inforData.infoLabel = this.inforData.infoLabel
        ? this.inforData.infoLabel.split(",")
        : [];
    }
  },
  methods: {
    toDetail() {
      this.$router.push({ name: "detail", params: { ...this.inforData } });
    },
    detailShow() {
      this.$emit("trueShow",this.inforData);
    },
  },
};
</script>
<style lang="less" scoped>
.gq-pc-card {
  display: block;
  padding-top: 24px;
  height: 574px;
  border-bottom: 2px solid #d8d8d8;
  border-top: 2px solid #fff;
}
.gq-pc-card:hover {
  border-top: 2px solid #648693;
  border-bottom: 2px solid #648693;
}
.gq-phone-card {
  display: none;
}
.gq-card-body {
  // height: 574px;
  width: 338px;

  .gq-card-img-box {
    width: 338px;
    height: 285px;
    img {
      width: 338px;
      height: 285px;
    }
  }
  .gq-card-txt-box {
    margin-top: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    .gq-card-btn-box {
      display: flex;
      text-align: center;
      .gq-card-btn {
        width: 75px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #ff9100;
        font-size: 14px;
        font-weight: 300;
        line-height: 30px;
        color: #ff9100;
      }
      .left {
        margin-left: 14px;
      }
    }
    .gq-card-name {
      margin-top: 16px;
      height: 54px;
      width: 338px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-size: 23px;
      font-weight: bold;
      color: #222222;
      cursor: pointer;
    }
    .gq-card-date {
      font-size: 12px;
      font-weight: 400;
      color: #222222;
      padding-top: 12px;
      padding-bottom: 22px;
    }
    .gq-card-txt {
      font-size: 14px;
      font-weight: 300;
      color: #888888;
    }
  }
}
@media only screen and (max-width: 768px) {
  .gq-card-body {
    height: 2.93rem;
    width: 9.44rem;
    border-bottom: 0px;

    .gq-pc-card {
      display: none;
    }
    .gq-phone-card {
      display: block;
      height: 2.93rem;
      overflow: hidden;
      width: 9.44rem;
      display: flex;
      .gq-card-img-box {
        width: 3.47rem;
        height: 2.93rem;
        img {
          width: 3.47rem;
          height: 2.93rem;
        }
      }

      .gq-card-txt-box {
        width: 5.6rem;
        margin-top: 0px;
        .gq-card-name {
          font-size: 0.37rem;
          height: 0.9rem;
          width: 5.6rem;
          margin-top: 0px;
          cursor: pointer;
        }
        .gq-card-date {
          padding-top: 0.27rem;
          font-size: 0.32rem;
          padding-bottom: 0.4rem;
          color: #888888;
        }
        .gq-card-btn-box {
          .gq-card-btn {
            width: 1.55rem;
            height: 0.53rem;
            font-size: 0.37rem;
            line-height: 0.5rem;
          }
        }
      }
    }
  }
}
</style>