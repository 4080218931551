<template>
  <div class="wplnav-width">
    <div class="wplnav-box">
      <div class="logo-box">
        <img class="logo-img" src="@/assets/imgs/jclogoW.png">
      </div>
      <ul>
        <li
          :class="item.router == tag ? 'active' : ''"
          v-for="(item, i) in list"
          :key="i"
          @click="toPage(item, i)"
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="nav-line"></div>
      <div class="nav-crmLogo">
        <!-- <img class="crm-logo" src="@/assets/imgs/crmLogo.png"> -->
        <div class="crm-logo"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    $route(to, from) {

      this.tag = to.path;
    },
  },
  data() {
    return {
      tag: '',
      list: [
        {
          name: "Home",
          show: false,
          router: "/",
          // children: [
          //   {
          //     name: "Recommendable rojects",
          //     id: "#recommendable-rojects",
          //   },
          //   {
          //     name: "Partner evaluation",
          //     id: "#partner-phone-evaluation",
          //   },
          //   {
          //     name: "Related information",
          //     id: "#related-phone-information",
          //   },
          // ],
        },
        {
          name: "news",
          show: false,
          router: "/news",
        },
        {
          name: "Institutional Partners",
          show: false,
          router: "/CooperativeProjects",
          children: [
            {
              name: "Canada",
              id: "id=0",
            },
            {
              name: "U.S.A",
              id: "id=1",
            },
            {
              name: "UK&EU",
              id: "id=2",
            },
            {
              name: "Australia",
              id: "id=3",
            },
            {
              name: "NZL&Asia",
              id: "id=4",
            },
          ],
        },
        {
          name: "Service",
          show: false,
          router: "/servicePage",
          children: [
            {
              name: "Study abroad information",
            },
            {
              name: "Application for admission",
            },
            {
              name: "Cooperative education",
            },
          ],
        },
        {
          name: "About us",
          show: false,
          router: "/aboutUs",
          children: [
            {
              name: "About us",
            },
            {
              name: "contact information",
            },
          ],
        },
        { // wp add 2022-10-11 添加一个导航，实现Iframe三方网站表单提交
          name: "Student Insurance",
          show: false,
          router: "/StudentInsurance",
        },
      ],
    }
  },
  methods: {
    toPage(d, i) {
      if (this.$route.path == d.router) return;
      this.$router.push(d.router);
    },
  }
}
</script>

<style lang="less" scoped>
.wplnav-width {
  margin-top: 10px;
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 17px;
}
.wplnav-box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 1440px;
  height: 80px;
  .logo-img {
    width: 140px;
    height: 88px;
    margin-right: 83px;
  }
  .crm-logo {
    width: 96px;
    height: 70px;
  }
  .nav-line {
    width: 2px;
    height: 73px;
    // background: #fff;
    margin-right: 80px;
    margin-left: 87px;
  }
  ul {
    display: flex;
    // margin-left: 52px;
    align-items: center;
    color: #FFFFFF;
    li {
      box-sizing: border-box;
      margin-right: 56px;
      height: 40px;
      min-width: 55px;
      font-size: 18px;
      font-family: Helvetica;
      font-weight: 300;
      // padding: 12px 0;
      line-height: 40px;
      cursor: pointer;
    }
    li:hover {
      border-bottom: 3px solid #7DC2CF;
    }
    .active {
      border-bottom: 3px solid #7DC2CF;
    } 
  }
}
</style>