<template>
  <div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, i) in bannerList" :key="i" @click="goLink(item.link)">
          <img
            :src="webType ? item.bannerRouteMobile : item.bannerRoutePc"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="gq-swiper-page">
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import { getBannerList } from "@/api";
export default {
  data() {
    return {
      bannerList: [],
      webType: window.webType(),
    };
  },
  methods: {
    bannerSrc(item) {
      if (window.webType()) {
        return item.bannerRouteMobile;
      } else {
        return item.bannerRoutePc;
      }
    },
    goLink(link) {
      if (link) window.open(link)
    },
    swiper() {
      var mySwiper = new Swiper(".swiper-container", {
        autoplay: {
          delay: 6000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        // // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
        },
        on: {
          touchStart: function (event) {
            //滑入
          },
          slideChange: function (e) {
            //滚动
          },
        },
      });
    },
    getlist() {
      getBannerList({ page: 1, rows: 5 }).then((res) => {
        this.bannerList = res;
        this.$nextTick(() => {
          this.swiper();
        });
      });
    },
  },
  mounted() {
    this.getlist();
  },
};
</script>
<style lang="less" scoped>
.swiper-container {
  width: 100%;
  //height: 700px;  wp  注释掉，以解决文件变形问题
  // position: absolute;
  
  img {
    width: 100%;
    height: 100%;
  }
}
/deep/.swiper-pagination-bullet-active {
  background: linear-gradient(136deg, #62818e 0%, #81ccd9 100%) !important;
  width: 60px !important;
  height: 10px !important;
  border-radius: 10px !important;
}
/deep/.swiper-pagination-bullet {
  margin: 0px 3px;
}
.gq-swiper-page {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 20px;
}
@media only screen and (max-width: 768px) {
  .swiper-container {
    height: 6.61rem;
    width: 10rem;
  }
  .gq-swiper-page {
    display: none;
  }
}
</style>