import axios from 'axios';
const request = axios.create({
     /*
     * wp  2022-9-15
     * 使用SSL证书下的访问路径
     * */
    //baseURL: "http://www.can-achieve.com/interface/"

    baseURL: "https://www.can-achieve.com/interface/"

})

// request.interceptors.request.use(

//     error => {
//         return Promise.reject(error)
//     }
// )
request.interceptors.response.use(
    response => {
        const res = response.data;
  
        // if (res.head.respStatus === '00') {
            return res.data;
        // } else {
        //    return Promise.reject(res)
        // }
    },
    // error => {
    //     return Promise.reject(error)
    // }
)

export default request