<template>
  <div style="overflow: hidden">
    <!-- pc端显示区 -->
    <div class="gq-nav-box">
      <div class="gq-nav-topSolid"></div>
      <div class="gq-nav-router-box">
        <div class="gq-nav-jcLogo-box">
          <img src="@/assets/imgs/jclogo.jpg" class="gq-nav-jcLogo-img" />
        </div>
        <ul class="gq-nav-router-parent">
          <li
            class="gq-nav-router-children"
            :class="item.router == tag ? 'gq-nav-router-active' : ''"
            v-for="(item, i) in list"
            :key="i"
            @click="toPage(item, i)"
          >
            {{ item.name }}
          </li>
        </ul>
<!--        wp   删除CRM系统入口   2022-05-16-->
<!--        <div class="gq-nav-CRMLogo-box">
          <img src="@/assets/imgs/crmLogo.png" class="gq-nav-CRMLogo-img" />
        </div>
        -->
<!--   wp end     -->
      </div>
      <!-- 2023.09.25 新增下载pdf -->
      <div class="gq-home-More" @click="openFile">
        Download Can-Achieve Brochure
      </div>
    </div>
    <!-- 移动端显示区 -->
    <div class="gq-nav-phone-box">
      <div class="gq-nav-phone">
        <div class="gq-nav-phone-div" v-if="routerName == 'Home'">
          <div class="gq-nav-phone-title-one">CAN-ACHIEVE</div>
          <div class="gq-nav-phone-title-two">Global</div>
        </div>
        <div v-else class="gq-nav-phone-div">
          <div class="gq-nav-back" @click="back"></div>
          <div class="gq-nav-jclogo"></div>
          <div class="gq-nav-name">
            {{ title }}
          </div>
        </div>
        <div
          class="gq-nav-wap-nav"
          :class="navTitleShow ? 'gq-nav-wap-nav-true' : ''"
          @click="navTitleShow = !navTitleShow"
        ></div>
      </div>
         <div class="zhezhao" v-show="navTitleShow" @click="navTitleShow = false"></div>
      <div
        class="gq-nav-phone-MenuBox fadeInRight animated"
        v-show="navTitleShow"
      >
     
        <div class="gq-nav-phone-MenuDiv">
          <div class="gq-nav-phone-Menu">Menu</div>
          <ul class="gq-nav-phone-parentUl">
            <li
              class="gq-nav-phone-parentLi"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="gq-nav-phone-parentLi-divBox">
                <div @click="toPage(item, index)">{{ item.name }}</div>
                <div
                  v-show="item.children"
                  class="gq-nav-phone-ringht"
                  :class="item.show ? 'bottomRotate' : ''"
                  @click="navChange(item, index)"
                ></div>
              </div>
              <ul class="gq-nav-phone-childUl animated" v-show="item.show">
                <li
                  class="gq-nav-phone-childli"
                  v-for="(child, i) in item.children"
                  :key="i + 1"
                  :class="i + 1 != item.children.length ? 'childBorder' : ''"
                  @click="toDetailPage(item, child)"
                >
                  {{ child.name }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    $route(to, from) {
      this.routerName = to.name;
      this.tag = to.path;
    },
  },

  computed: {
    title() {
      return this.navList[this.routerName];
    },
  },
  data() {
    return {
      navTitleShow: false,
      // activeIndex: 0,
      tag: "/",
      routerName: "Home",
      navList: {
        news: "Related information",
        detail: "Cooperative Projects",
        projectDetail: "Cooperative Projects",
        CooperativeProjects: "Cooperative Projects",
        aboutUs: "Cooperative Projects",
        servicePage: "Cooperative Projects",
      },
      list: [
        {
          name: "Home",
          show: false,
          router: "/",
          // children: [
          //   {
          //     name: "Recommendable rojects",
          //     id: "#recommendable-rojects",
          //   },
          //   {
          //     name: "Partner evaluation",
          //     id: "#partner-phone-evaluation",
          //   },
          //   {
          //     name: "Related information",
          //     id: "#related-phone-information",
          //   },
          // ],
        },
        {
          name: "News",
          show: false,
          router: "/news",
        },
        {
          name: "Institutional Partners",
          show: false,
          router: "/CooperativeProjects",
          children: [
            {
              name: "Canada",
              id: "1",
            },
            {
              name: "U.S.A",
              id: "2",
            },
            {
              name: "UK&EU",
              id: "3",
            },
            {
              name: "Australia",
              id: "4",
            },
            {
              name: "NZL&Asia",
              id: "5",
            },
          ],
        },
        {
          name: "Service",
          show: false,
          router: "/servicePage",
          children: [
            // {
            //   name: "Study abroad information",
            //   id: "1",
            // },
            {
              name: "Recruitment of foreign teachers",
              id: "2",
            },
            {
              name: "Immigration business",
              id: "3",
            },
          ],
        },
        {
          name: "About us",
          show: false,
          router: "/aboutUs",
          children: [
            {
              name: "Company profile",
              id: "1",
            },
            {
              name: "Contact information",
              id: "2",
            },
          ],
        },
        { // wp add 2022-10-11 添加一个导航，实现Iframe三方网站表单提交
          name: "Student Insurance",
          show: false,
          router: "/StudentInsurance",
        }
      ],
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    toDetailPage(x, y) {
      if (this.$route.name == x.router && this.$route.query.id == y.id) {
        return;
      }
      this.$router.push({ path: x.router, query: { id: y.id } });
    },
    toPage(d, i) {
      if (this.$route.path == d.router) return;
      // this.activeIndex = i;
      this.$router.push(d.router);
    },
    navChange(d, i) {
      this.list.forEach((item, index) => {
        if (index != i) {
          item.show = false;
        }
      });
      d.show = !d.show;
    },
    // 打开文件
    openFile(){
      window.open('https://iescrm.oss-cn-hangzhou.aliyuncs.com/Can-Achieve%20Brochure.pdf')
    },
  },
};
</script>
<style lang="less" scoped>
.gq-nav-phone-box {
  display: none;
}
.gq-body {
  width: 1460px;
  margin: 0 auto;
}
.gq-nav-topSolid {
  width: 100%;
  height: 8px;
  background: linear-gradient(270deg, #62818e 0%, #81ccd9 100%);
}
.gq-nav-router-box {
  height: 122px;
  // width: 100%;
  justify-content: center;
  padding: 0px 186px;
  display: flex;
}
.gq-nav-jcLogo-box {
  width: 140px;
  height: 88px;
  margin-top: 16px;
  margin-bottom: 18px;
  display: inline-block;
}
.gq-nav-jcLogo-img {
  width: 140px;
  height: 88px;
}
.gq-nav-router-parent {
  display: flex;
  width: 850px;
  // margin: 0 auto;
  align-self: center;
  justify-content: space-around;
}
.gq-nav-router-children {
  font-size: 19px;
  font-family: Helvetica;
  font-weight: 300;
  line-height: 22px;
  color: #333333;
  border-bottom: 2px solid #fff;
  cursor: pointer;
}
.gq-nav-router-active {
  border-bottom-color: #7dc2cf;
}
.gq-nav-router-children:hover {
  border-bottom-color: #7dc2cf;
}
.gq-nav-CRMLogo-box {
  width: 154px;
  height: 122px;
  background: linear-gradient(270deg, #62818e 0%, #81ccd9 100%);
}
.gq-nav-CRMLogo-img {
  width: 78px;
  height: 54px;
  display: block;
  margin: auto;
  margin-top: 30px;
}
.gq-home-More {
  position: absolute;
  right: 20px;
  top: 30px;
  padding: 5px 10px;
  height: 42px;
  line-height: 42px;
  border: 1px solid #7fc9d6;
  opacity: 1;
  border-radius: 4px;
  font-size: 20px;
  font-weight: normal;
  color: #7fc8d5;
  font-style: oblique;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .gq-nav-box {
    display: none;
  }
  .zhezhao {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
  }
  .gq-nav-phone-box {
    display: block;
  }
  .gq-nav-phone {
    height: 0.56rem;
    width: 100%;
    background: linear-gradient(90deg, #62818e 0%, #81ccd9 100%);
    display: flex;
    justify-content: space-between;
    font-size: 0.48rem;
    font-family: Helvetica;
    font-weight: bold;
    line-height: 0.56rem;
    padding: 0.4rem 0rem 0.37rem 0rem;
    .gq-nav-phone-div {
      display: flex;
      justify-content: space-between;
      padding-left: 0.37rem;
      .gq-nav-back {
        background: url("../assets/logos/back.png") no-repeat;
        background-size: 100%;
        width: 0.3rem;
        height: 0.57rem;
      }
      .gq-nav-jclogo {
        background: url("../assets/logos/jclogo.png") no-repeat;
        background-size: 100%;
        width: 1.32rem;
        height: 1rem;
        margin-left: 0.5rem;
        margin-top: -0.2rem;
      }
      .gq-nav-name {
        font-size: 0.32rem;
        font-weight: bold;
        color: #ffffff;
        height: 0.5rem;
        margin-left: 0.4rem;
        padding-left: 0.35rem;
        border-left: 1px solid #fff;
        width: 5rem;
      }
    }
  }
  .gq-nav-phone-title-one {
    color: #ffffff;
    width: 3.4rem;
    height: 0.56rem;
  }
  .gq-nav-phone-title-two {
    color: #648592;
    width: 1.71rem;
    height: 0.53rem;
    background: #ffffff;
    text-align: center;
    border-radius: 0.1rem;
    margin-left: 0.2rem;
  }
  .gq-nav-wap-nav {
    background: url("../assets/logos/wap-nav.png") no-repeat;
    background-size: 100%;
    height: 0.7rem;
    width: 0.7rem;
    margin-right: 0.4rem;
    margin-top: -0.1rem;
  }
  .gq-nav-wap-nav-true {
    transform: rotate(90deg);
  }
  .gq-nav-phone-MenuBox {
    width: 8rem;
    padding-bottom: 1rem;
    background: #6f9fac;
    color: #fff;
    position: absolute;
    top: 1.33;
    right: 0;
    z-index: 99;
  }
  .gq-nav-phone-MenuDiv {
    margin-left: 0.69rem;
    .gq-nav-phone-Menu {
      text-align: center;
      font-size: 0.8rem;
      font-family: Helvetica;
      font-weight: normal;
      line-height: 0.93rem;
      height: 1.07rem;
      padding-top: 0.26rem;
      vertical-align: middle;
    }
    .gq-nav-phone-parentUl {
      border-top: 1px solid #81ccd9;
    }
    .gq-nav-phone-parentLi {
      border-bottom: 1px solid #81ccd9;
      min-height: 1.17rem;
      padding-top: 0.56rem;
      padding-right: 0.2rem;
      .gq-nav-phone-parentLi-divBox {
        display: flex;
        justify-content: space-between;
      }
      div {
        font-size: 0.53rem;
        font-weight: bold;
      }
      .gq-nav-phone-ringht {
        background: url("../assets/logos/right.png") no-repeat;
        background-size: 100%;
        height: 0.8rem;
        width: 0.7rem;
      }
      .bottomRotate {
        transform: rotate(90deg);
      }
      .gq-nav-phone-childUl {
        width: 6.91rem;
        padding-left: 0.4rem;
        .gq-nav-phone-childli {
          font-size: 0.37rem;
          font-weight: 300;
          line-height: 1.6rem;
        }
        .childBorder {
          border-bottom: 1px solid #81ccd9;
        }
      }
    }
  }
}
</style>