import request from '@/utils/request';
// import request from 'axios';


//banner图
export const getBannerList = (data) => {
    return request({
        method: "post",
        url: "/banner/getBannerWithoutToken",
        data
    })
}

//项目橱窗
export const projectDisplay = () => {
    return request({
        method: "get",
        url: "/project/display",
    })
}
//首页-资讯-列表
export const iesInfoList = (data) => {
    return request({
        method: "post",
        url: "/iesInfo/list",
        data
    })
}
//资讯-详情
export const iesInfoGetById = (params) => {
    return request({
        method: "get",
        url: "/iesInfo/getById",
        params
    })
}
//资讯-全部国家项目列表
export const projectProjectByCountry = () => {
    return request({
        method: "get",
        url: "/project/projectByCountry",
    })
}
//资讯-全部国家项目列表
export const projectList = (data) => {
    return request({
        method: "post",
        url: "/project/list",
        data
    })
}
//项目-列表
export const rojectGetById = (params) => {
    return request({
        method: "get",
        url: "/project/getById",
        params
    })
}