import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Image as VanImage } from 'vant';
import { Pagination } from 'element-ui';
Vue.config.productionTip = false

//rem 转换插件
import 'amfe-flexible';
import "swiper/dist/css/swiper.min.css";
import 'vant/lib/index.css';
import '@/style/config.css';
import '@/style/animate.min.css';
import 'element-ui/lib/theme-chalk/pagination.css'
import 'element-ui/lib/theme-chalk/icon.css'
window.webType = function () {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
};
Vue.component(VanImage.name, VanImage);
Vue.component(Pagination.name, Pagination);
const app = new Vue({
  router,
  VanImage,
  render: h => h(App)
}).$mount('#app')

