<template>
  <div>
    <div class="gq-bottom-detail-box">
      <div class="gq-bottom-top-box">
        <div class="gq-bottom-width">
          <div class="gq-bottom-ul-box">
            <ul
              class="gq-bottom-parentUl"
              v-for="(item, index) in list"
              :key="index"
              :class="index + 1 != list.length ? 'gq-bottom-border' : ''"
            >
              <li class="gq-bottom-parentLi-title" @click="topage(item)">
                {{ item.name }}
              </li>
              <li
                class="gq-bottom-parentLi"
                v-for="(child, i) in item.list"
                :key="i + 1"
                @click="topage(item, child)"
              >
                {{ child.name }}
              </li>
            </ul>
          </div>
          <div class="gq-bottom-contact-title">
            <div>
              Bright Can-Achieve
            </div>

<!--           wp  添加 推特 与领英, FB 的链接 -->
            <div class="wp-linkin">
              <a href="https://www.linkedin.com/in/offical-can-achieve-833593226/" target="_top"><img src="../assets/logos/li-in-bug.png" alt=""></a>
            </div>
            <div class="wp-twitter">
              <a href="https://twitter.com/Can_Achieve_Edu" target="_top"><img src="../assets/logos/2021_twitter_logo.png" alt=""></a>
            </div>
            <div class="wp-fb">
              <a href="https://www.facebook.com/profile.php?id=100076311985426" target="_top"><img src="../assets/logos/f_logo_RGB-Blue_100.png" alt=""></a>
            </div>
<!--            wp end -->
          </div>
          <div class="gq-bottom-contact-box">
            <div
              class="gq-bottom-contact-line gq-bottom-border"
              style="width: 250px"
            >
              <div class="gq-bottom-contact-child">
                <span class="gq-bottom-contact-logo">
                  <img src="../assets/logos/qipao.png" alt="" />
                </span>
                <span class="gq-bottom-contact-titleSpan">Phone</span>
                <span class="gq-bottom-contact-detail">8610-5869.9445</span>
              </div>
              <div class="gq-bottom-contact-child" style="margin-top: 25px">
                <span class="gq-bottom-contact-logo">
                  <img src="../assets/logos/dayin.png" alt="" />
                </span>
                <span class="gq-bottom-contact-titleSpan">Fax</span>
                <span class="gq-bottom-contact-detail">8610-5869.4171</span>
              </div>
            </div>
            <div class="gq-bottom-contact-line" style="margin-left: 50px">
              <div class="gq-bottom-contact-child">
                <span class="gq-bottom-contact-logo">
                  <img src="../assets/logos/email.png" alt="" />
                </span>
                <span class="gq-bottom-contact-titleSpan">E-mail</span>
                <span class="gq-bottom-contact-detail"
                  >Marketing@can-achieve.com</span
                >
              </div>
              <div class="gq-bottom-contact-child" style="margin-top: 25px">
                <span class="gq-bottom-contact-logo">
                  <img src="../assets/logos/dingwei.png" alt="" />
                </span>
                <span class="gq-bottom-contact-titleSpan">Add</span>
                <span class="gq-bottom-contact-detail"
                  >802,Tower B,JianWai SOHO Office Building,No.39,Dongsanhuan
                  Zhonglu Chaoyang District,Beijing,100022,China</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gq-bottom-bottom-box">
        © 2008-2022 Bright Can-Achieve
        <a
          href="http://can-achieve.com.cn/legal.php"
          target="_bank"
          style="color:#fff"
          >隐私政策</a
        >
        <a href=" https://beian.miit.gov.cn" target="_blank" style="color: #fff;margin-left: 16px;"
          >京ICP备10037726号-35</a
        >
      </div>
    </div>
    <div class="gq-bottom-detail-phone-box">
      <div class="box">
        <div class="gq-bottom-detail-phone-title">
          Bright Can-Achievea a <br />Bright Scholar company
        </div>
        <ul class="gq-bottom-contact-phone-ul">
          <li class="gq-bottom-contact-phone-li">
            <span class="gq-bottom-contact-logo">
              <img src="../assets/logos/qipao.png" alt="" />
            </span>
            <span class="gq-bottom-contact-titleSpan">Phone</span>
            <span class="gq-bottom-contact-detail">8610-5869.9445</span>
          </li>
          <li class="gq-bottom-contact-phone-li">
            <span class="gq-bottom-contact-logo">
              <img src="../assets/logos/dayin.png" alt="" />
            </span>
            <span class="gq-bottom-contact-titleSpan">Fax</span>
            <span class="gq-bottom-contact-detail">8610-5869.4171</span>
          </li>
          <li class="gq-bottom-contact-phone-li">
            <span class="gq-bottom-contact-logo">
              <img src="../assets/logos/email.png" alt="" />
            </span>
            <span class="gq-bottom-contact-titleSpan">E-mail</span>
            <span class="gq-bottom-contact-detail"
              >Marketing@can-achieve.com</span
            >
          </li>
          <li class="gq-bottom-contact-phone-li" style="display: flex">
            <div>
              <span class="gq-bottom-contact-logo">
                <img src="../assets/logos/dingwei.png" alt="" />
              </span>
              <span class="gq-bottom-contact-titleSpan">Add</span>
            </div>
            <span class="gq-bottom-contact-detail" style="width: 7rem"
              >802,Tower B,JianWai SOHO Office<br />
              Building,No.39,Dongsanhuan Zhonglu Chaoyang
              District,Beijing,100022,China</span
            >
          </li>
        </ul>
      </div>

      <div class="gq-phone-bottom-box">© 2008-2021 Bright Can-Achieve</div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    topage(x, y) {
      if (this.$route.name == x.router && this.$route.query.id == y.id) {
        return;
      }
      if (y) {
        this.$router.push({ path: x.router, query: { id: y.id } });
      } else {
        this.$router.push({ path: x.router });
      }
    },
  },
  data() {
    return {
      list: [
        {
          name: "Home",
          router: "/",
          list: [
            {
              name: "Highlight Programs",
              id: "#recommendable-rojects",
            },
            {
              name: "Feedback from Partners",
              id: "#partner-evaluation",
            },
            {
              name: "Industry Updates",
              id: "#related-information",
            },
          ],
        },
        {
          name: "News",
          router: "/news",
        },
        {
          name: "Institutional Partners",
          router: "CooperativeProjects",
          list: [
            {
              name: "Canada",
              id: "1",
            },
            {
              name: "U.S.A",
              id: "2",
            },
            {
              name: "UK&EU",
              id: "3",
            },
            {
              name: "Australia",
              id: "4",
            },
            {
              name: "NZL&Asia",
              id: "5",
            },
          ],
        },
        {
          name: "Service",
          router: "/servicePage",
          list: [
            // {
            //   name: "Study abroad information",
            //   id: "1",
            // },
            {
              name: "Recruitment of foreign teachers",
              id: "2",
            },
            {
              name: "Immigration business",
              id: "3",
            },
          ],
        },
        {
          name: "About us",
          router: "/aboutUs",
          list: [
            {
              name: "Company profile",
              id: "1",
            },
            {
              name: "Contact information",
              id: "2",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.gq-bottom-detail-phone-box {
  display: none;
}
.gq-bottom-detail-box {
  width: 100%;
  min-width: 1440px;
  // height: 604px;
  .gq-bottom-top-box {
    height: 442px;
    .gq-bottom-width {
      width: 1440px;
      margin: 0 auto;
      //    display: flex;
      // justify-content: center;
      // flex-direction: column;
    }
    background: #eeeeee;
    // padding-left: 185px;
    // padding-right: 185px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .gq-bottom-bottom-box {
    height: 116px;
    background: linear-gradient(226deg, #62818e 0%, #81ccd9 100%);
    font-size: 14px;
    font-family: Helvetica;
    font-weight: 400;
    line-height: 116px;
    color: #ffffff;
    text-align: center;
  }
  .gq-bottom-ul-box {
    display: flex;
    margin: 0 auto;
    width: 1200px;
  }
  .gq-bottom-parentUl {
    min-width: 134px;
    padding-left: 40px;
    padding-right: 40px;
    height: 186px;
    margin-bottom: 55px;
  }
  .gq-bottom-border {
    border-right: 1px solid #d8d8d8;
  }
  .gq-bottom-parentLi-title {
    font-size: 23px;
    font-family: Helvetica;
    font-weight: normal;
    line-height: 27px;
    color: #333333;
    font-style: oblique;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .gq-bottom-parentLi {
    // height: 78px;
    font-size: 11px;
    font-family: Helvetica;
    font-weight: 300;
    line-height: 28px;
    color: #333333;
    // letter-spacing: 19px;
    cursor: pointer;
    opacity: 1;
  }
  .gq-bottom-parentLi:hover {
    color: #80cad7;
  }
  .gq-bottom-contact-title {
    width: 1200px;
    margin-left: 160px;
    font-size: 37px;
    font-family: Helvetica;
    font-weight: bold;
    line-height: 46px;
    color: #222222;
  }
  /*
     修改样式，以添加领英及推特的链接logo
     wp add 2022-05-24
  */
  .gq-bottom-contact-title div {
    display: inline;
    margin-left: 20px;
      img{
        max-height: 30px;
      }
  }
  /*
    wp  end
  */
  .gq-bottom-contact-box {
    margin-top: 24px;
    display: flex;
    width: 1200px;
    margin-left: 160px;
  }
  .gq-bottom-contact-line {
    display: flex;
    flex-direction: column;

    .gq-bottom-contact-child {
      font-size: 14px;
      .gq-bottom-contact-logo {
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }
      }
      .gq-bottom-contact-titleSpan {
        color: #959595;
        font-weight: normal;
        margin-left: 7px;
        width: 40px;
        display: inline-block;
      }
      .gq-bottom-contact-detail {
        color: #222222;
        margin-left: 36px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .gq-bottom-detail-box {
    display: none;
  }
  .gq-bottom-detail-phone-box {
    display: block;
    height: 9.03rem;
    background-color: rgb(248, 248, 248);
    padding-top: 1rem;
    .gq-bottom-detail-phone-title {
      font-size: 0.8rem;
      font-family: Helvetica;
      font-weight: bold;
      color: #222222;
      width: 100%;
    }
    .box {
      padding-left: 0.27rem;
      padding-right: 0.27rem;
    }
    .gq-phone-bottom-box {
      height: 2rem;
      background: linear-gradient(180deg, #62818e 0%, #81ccd9 100%);
      text-align: center;
      padding-top: 0.59rem;
      padding-left: 1.24rem;
      padding-right: 1.24rem;
      font-size: 0.32rem;
      font-family: Helvetica;
      font-weight: 300;
      color: #ffffff;
      line-height: 0.53rem;
    }
    .gq-bottom-contact-phone-ul {
      margin-top: 0.59rem;
      margin-bottom: 0.8rem;
      .gq-bottom-contact-phone-li {
        font-size: 0.32rem;
        height: 0.9rem;
        img {
          width: 0.54rem;
          height: 0.54rem;
          vertical-align: middle;
        }
        span {
          display: inline-block;
        }
        .gq-bottom-contact-titleSpan {
          font-weight: normal;
          color: #959595;
          width: 0.95rem;
          margin-left: 0.29rem;
        }
        .gq-bottom-contact-detail {
          font-weight: 300;
          color: #222222;
          margin-left: 0.45rem;
        }
      }
    }
  }
}
</style>
