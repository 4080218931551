<template>
  <div style="overflow: hidden">
    <!-- <gqswiper /> -->
    <div class="view-body">
      <div class="gq-home-recommend-box" id="recommendable-rojects">
        <div class="gq-home-recommend-title-box">
          <div class="gq-home-recommend-title">Highlight Programs</div>
          <div class="gq-home-recommend-introduce">
            2023 highlight programs <br />
            Quick access to global top school offers
          </div>
        </div>
      </div>
      <div class="gq-home-picture-box">
        <div class="gq-home-picture-flex flex">
          <div class="gq-home-picture-child">
            <div
              class="gq-home-picture-img-item gq-home-picture-content"
              v-if="projectList[0]"
              @click="projectTrueShow(projectList[0])"
            >
              <img :src="projectList[0].projectDisplay" alt="" />
              <div class="gq-home-picture-contentBox">
                <div class="gq-home-picture-content-title">
                  {{ projectList[0].projectName }}
                </div>
              </div>
            </div>
            <div class="flex">
              <div
                class="gq-home-picture-img-half gq-home-picture-content"
                v-if="projectList[1]"
                @click="projectTrueShow(projectList[1])"
              >
                <img :src="projectList[1].projectDisplay" alt="" />
                <div class="gq-home-picture-contentBox">
                  <div class="gq-home-picture-content-title">
                    {{ projectList[1].projectName }}
                  </div>
                </div>
              </div>
              <div
                class="gq-home-picture-img-half gq-home-picture-content"
                v-if="projectList[2]"
                @click="projectTrueShow(projectList[2])"
              >
                <img :src="projectList[2].projectDisplay" alt="" />
                <div class="gq-home-picture-contentBox">
                  <div class="gq-home-picture-content-title">
                    {{ projectList[2].projectName }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="gq-home-picture-img-item gq-home-picture-content"
              v-if="projectList[3]"
              @click="projectTrueShow(projectList[3])"
            >
              <img :src="projectList[3].projectDisplay" alt="" />
              <div class="gq-home-picture-contentBox">
                <div class="gq-home-picture-content-title">
                  {{ projectList[3].projectName }}
                </div>
              </div>
            </div>
          </div>
          <div class="gq-home-picture-child">
            <div
              class="gq-home-picture-img-bigitem gq-home-picture-content"
              v-if="projectList[4]"
              @click="projectTrueShow(projectList[4])"
            >
              <img :src="projectList[4].projectDisplay" alt="" />
              <div class="gq-home-picture-contentBox">
                <div class="gq-home-picture-content-title">
                  {{ projectList[4].projectName }}
                </div>
              </div>
            </div>
            <div class="flex">
              <div
                class="gq-home-picture-img-half gq-home-picture-content"
                v-if="projectList[5]"
                @click="projectTrueShow(projectList[5])"
              >
                <img :src="projectList[5].projectDisplay" alt="" />
                <div class="gq-home-picture-contentBox">
                  <div class="gq-home-picture-content-title">
                    {{ projectList[5].projectName }}
                  </div>
                </div>
              </div>
              <div
                class="gq-home-picture-img-half gq-home-picture-content"
                v-if="projectList[6]"
                @click="projectTrueShow(projectList[6])"
              >
                <img :src="projectList[6].projectDisplay" alt="" />
                <div class="gq-home-picture-contentBox">
                  <div class="gq-home-picture-content-title">
                    {{ projectList[6].projectName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gq-home-picture-phone-box">
        <div class="gq-home-picture-flex">
          <div
            class="gq-home-picture-img-half gq-home-picture-content"
            v-if="projectList[0]"
            @click="toDetail(projectList[0])"
          >
            <img :src="projectList[0].projectDisplay" alt="" />
            <div class="gq-home-picture-name">
              {{ projectList[0].projectName }}
            </div>
          </div>
          <div
            class="gq-home-picture-img-half gq-home-picture-content"
            v-if="projectList[1]"
            @click="toDetail(projectList[1])"
          >
            <img :src="projectList[1].projectDisplay" alt="" />
            <div class="gq-home-picture-name">
              {{ projectList[1].projectName }}
            </div>
          </div>
          <div
            class="gq-home-picture-img-item gq-home-picture-content"
            v-if="projectList[2]"
            @click="toDetail(projectList[2])"
          >
            <img :src="projectList[2].projectDisplay" alt="" />
            <div class="gq-home-picture-name">
              {{ projectList[2].projectName }}
            </div>
          </div>
          <div
            class="gq-home-picture-img-half gq-home-picture-content"
            v-if="projectList[3]"
            @click="toDetail(projectList[3])"
          >
            <img :src="projectList[3].projectDisplay" alt="" />
            <div class="gq-home-picture-name">
              {{ projectList[3].projectName }}
            </div>
          </div>
        </div>
        <div class="gq-home-picture-flex">
          <div
            class="gq-home-picture-img-item gq-home-picture-content"
            v-if="projectList[4]"
            @click="toDetail(projectList[4])"
          >
            <img :src="projectList[4].projectDisplay" alt="" />
            <div class="gq-home-picture-name">
              {{ projectList[4].projectName }}
            </div>
          </div>
          <div
            class="gq-home-picture-img-half gq-home-picture-content"
            v-if="projectList[5]"
            @click="toDetail(projectList[5])"
          >
            <img :src="projectList[5].projectDisplay" alt="" />
            <div class="gq-home-picture-name">
              {{ projectList[5].projectName }}
            </div>
          </div>
          <div
            class="gq-home-picture-img-item gq-home-picture-content"
            v-if="projectList[6]"
            @click="toDetail(projectList[6])"
          >
            <img :src="projectList[6].projectDisplay" alt="" />
            <div class="gq-home-picture-name">
              {{ projectList[6].projectName }}
            </div>
          </div>
        </div>
      </div>

      <div class="gq-home-phone-picture-box"></div>
      <div class="gq-home-More-box">
        <div class="gq-home-More" @click="toPage('/CooperativeProjects')">
          More
        </div>
      </div>
    </div>
    <div class="gq-home-partner-box" id="partner-evaluation">
      <div class="gq-home-partner-title">Feedback from Partners</div>
      <div class="gq-swiper-box">
        <div class="swiper-button-prev swiper-button-prev-pc"></div>
        <div
          class="cooperation-swiper-container cooperation-swiper-container-pc"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide flex"
              v-for="(item, i) in teacherList"
              :key="i"
            >
              <div class="gq-home-img-box">
                <img
                  :src="require(`@/assets/imgs/${item.imgName}.jpg`)"
                  alt=""
                />
              </div>
              <div class="gq-home-swiper-introduce">
                <div class="gq-home-swiper-name">
                  {{ item.name }} <span>{{ item.title }}</span>
                </div>
                <div class="gq-home-swiper-detail">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next swiper-button-next-pc"></div>
      </div>
      <div class="gq-swiper-logo-box">
        <div
          class="gq-swiper-logo-div gq-swiper-logo-div-pc"
          :index="i"
          v-for="(item, i) in teacherList"
          :key="i"
        >
          <div class="gq-swiper-logo-shade"></div>
          <img :src="require(`@/assets/imgs/${item.imgName}.jpg`)" alt="" />
        </div>
      </div>
    </div>
    <div class="view-body">
      <div class="gq-home-partner-phone-box">
        <div class="gq-home-partner-title">Partner evaluation</div>
        <div class="gq-swiper-logo-box">
          <div
            class="gq-swiper-logo-div gq-swiper-logo-div-phone"
            :index="i"
            v-for="(item, i) in teacherList"
            :key="i"
          >
            <div class="gq-swiper-logo-shade"></div>
            <img :src="require(`@/assets/imgs/${item.imgName}.jpg`)" alt="" />
          </div>
        </div>

        <div
          class="
            cooperation-swiper-container cooperation-swiper-phone-container
          "
        >
          <div class="swiper-button-prev swiper-button-prev-phone"></div>
          <div class="swiper-wrapper">
            <div
              class="swiper-slide flex"
              v-for="(item, i) in teacherList"
              :key="i"
            >
              <div class="gq-home-img-box">
                <img
                  :src="require(`@/assets/imgs/${item.imgName}.jpg`)"
                  alt=""
                />
              </div>
              <div class="gq-home-swiper-introduce">
                <div class="gq-home-swiper-name">{{ item.name }}</div>
                <div class="gq-home-swiper-jieshao">
                  {{ item.title }}
                </div>
                <div class="gq-home-swiper-detail">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-next swiper-button-next-phone"></div>
        </div>
      </div>
      <div class="gq-home-information-box" id="related-information">
        <div class="gq-home-information-title">Industry Updates</div>
        <div class="gq-home-information-title-detail">
          <div>
            Learn the most up-to-date industry updates Fast access to famous
            universities offers
          </div>
        </div>

        <div class="gq-home-information-list-box">
          <information
            v-for="(item, i) in inforList"
            :key="i"
            :inforData="item"
            @trueShow="trueShow"
          />
        </div>
        <div class="gq-home-information-phone-list-box">
          <div
            v-for="(item, i) in inforList"
            :key="i"
            @click="toinforDetail(item)"
          >
            <phone-information v-if="i % 2 == 0" :inforData="item" />
            <phoneleft-information v-else :inforData="item" />
          </div>
        </div>
        <div class="gq-home-More-box">
          <div @click="toPage('/news')">More</div>
        </div>
      </div>

      <detail v-show="detailShow" ref="refdetail" @changeShow="falseShow" />
      <projectDetail
        v-show="projectDetailShow"
        ref="refProjectDetail"
        @changeShow="projectFalseShow"
      />
    </div>
    <gqIntroduction />
  </div>
</template>
<script>
import Swiper from "swiper";

import gqswiper from "@/components/gq-swiper.vue";
// import information from "@/components/pc-information.vue";
import information from "@/views/news/components/card.vue";
import phoneInformation from "./components/phone-information.vue";
import phoneleftInformation from "./components/phoneleft-information.vue";
import gqIntroduction from "@/components/gq-introduction.vue";
import detail from "@/components/detail.vue";
import projectDetail from "@/components/projectDetail";
import { iesInfoList, projectDisplay } from "@/api";
export default {
  components: {
    gqswiper,
    information,
    phoneInformation,
    phoneleftInformation,
    gqIntroduction,
    detail,
    projectDetail,
  },
  watch: {
    $route(to, from) {
      if (to.query.id) {
        this.$nextTick(() => {
          let x = document.querySelector(to.query.id).offsetTop;
          window.scrollTo(0, x);
        });
      }
    },
  },

  data() {
    return {
      inforList: [],
      projectList: [],
      detailShow: false,
      projectDetailShow: false,
      teacherList: [
        {
          name: "Douglas Zander",
          imgName: "one",
          title: `Ed.D.
                  Executive Director Office of Admissions
                 University of Delaware`,
          content: `we have seen students funneled through Can-Achieve
                    coming to the University, grew academic and personal
                    experience with excellent learning environment and
                    outcomes we provide here at University of Delaware.
                    Then they moved on to have internships, jobs or went
                    back to China and started new chapter of their lives.
                    Together, we have helped students through highs and
                    lows in the international higher education scenes. We
                    look forward to many more years of cooperation and
                    caring for the students with Can-Achieve.`,
        },
        {
          name: "Simon Foster",
          imgName: "two",
          title:
            "Deputy Director: International Officer at Loughborough University",
          content: `The team at Can-Achieve are extremely professional,
                knowledgeable and friendly. Loughborough university is
                proud to call Can-Achieve our partners and friends.`,
        },
        {
          name: "Brett Berquist",
          imgName: "three",
          title: "Director International of University of Auckland",
          content: `Can-Achieve is a trusted partner of The University of
            Auckland and we are grateful for the strong collaboration
            in supporting our Chinese students.`,
        },
        {
          name: "Brett Berquist",
          imgName: "four",
          title: "Director International of University of Auckland",
          content: `Can-Achieve is a trusted partner of The University of
            Auckland and we are grateful for the strong collaboration
            in supporting our Chinese students.`,
        },
        {
          name: "Tom Buckley",
          imgName: "five",
          title:
            "Director of Recruitment,Admissions and International Development at Lancaster University",
          content: `Can-Achieve have been a loyal and committed supporter
                of Lancaster University. Their focus on quality and
                student success sets them apart in a truly competitive
                sector.`,
        },
      ],
    };
  },
  methods: {
    // imgName(x){
    //   return `@/assets/imgs/${x}.png`
    // },
    toinforDetail(item) {
      this.$router.push({ name: "detail", params: { ...item } });
    },
    toDetail(item) {
      this.$router.push({ name: "projectDetail", params: { ...item } });
    },
    falseShow() {
      this.detailShow = false;
    },
    projectFalseShow() {
      this.projectDetailShow = false;
    },
    projectTrueShow(item) {
      this.$refs.refProjectDetail.setData(item);
      this.$refs.refProjectDetail.toTop()
      this.projectDetailShow = true;
    },
    trueShow(item) {
      this.$refs.refdetail.setData(item);
      this.detailShow = true;
    },
    toPage(x) {
      this.$router.push(x);
    },
    getProjectList() {
      projectDisplay().then((res) => {
        res.forEach((item) => {
          item.displayNum = Number(/\d/.exec(item.displayNum));
        });
        this.projectList = res.sort((x, y) => x.displayNum - y.displayNum);
      });
    },
    toTop() {
      window.scrollTo(0, 0);
    },
    getInforList() {
      let _that = this;
      iesInfoList({ rows: 3, page: 1 }).then((res) => {
        _that.inforList = res.rows;
      });
    },
    getBanner() {
      bannerList({ rows: 5, page: 0 }).then((res) => {});
    },
    pc() {
      var pcSwiper = new Swiper(".cooperation-swiper-container-pc", {
        navigation: {
          nextEl: ".swiper-button-next-pc",
          prevEl: ".swiper-button-prev-pc",
        },
        on: {
          touchStart: function (event) {
            //滑入
          },
          slideChange: function () {
            //滚动
            let i = 0;

            if (pcSwiper) {
              i = pcSwiper.activeIndex;
            }
            let dom = document.getElementsByClassName("gq-swiper-logo-div-pc");
            changeLogo(dom, dom[i]);
          },
        },
      });
      let dom = document.getElementsByClassName("gq-swiper-logo-div-pc");
      for (let i = 0; i < dom.length; i++) {
        dom[i].onclick = function (e) {
          changeLogo(dom, dom[i]);

          pcSwiper.activeIndex = i;
          pcSwiper.slideTo(i, 500, false);
        };
      }
      function changeLogo(list, d) {
        for (var i = 0; i < list.length; i++) {
          list[i].firstChild.style.display = "block";
        }
        d.firstChild.style.display = "none";
      }
    },
    phone() {
      var phoneSwiper = new Swiper(".cooperation-swiper-phone-container", {
        navigation: {
          nextEl: ".swiper-button-next-phone",
          prevEl: ".swiper-button-prev-phone",
        },
        on: {
          touchStart: function (event) {
            //滑入
          },
          slideChange: function () {
            //滚动
            let i = 0;
            if (phoneSwiper) {
              i = phoneSwiper.activeIndex;
            }
            let dom = document.getElementsByClassName(
              "gq-swiper-logo-div-phone"
            );
            changeLogo(dom, dom[i]);
          },
        },
      });
      let dom = document.getElementsByClassName("gq-swiper-logo-div-phone");
      for (let i = 0; i < dom.length; i++) {
        dom[i].onclick = function (e) {
          changeLogo(dom, dom[i]);

          phoneSwiper.activeIndex = i;
          phoneSwiper.slideTo(i, 500, false);
        };
      }
      function changeLogo(list, d) {
        for (var i = 0; i < list.length; i++) {
          list[i].firstChild.style.display = "block";
        }
        d.firstChild.style.display = "none";
      }
    },
  },
  created() {
    this.getProjectList();
    this.getInforList();
  },
  mounted() {
    if (this.$route.query.id) {
      this.$nextTick(() => {
        let x = document.querySelector(this.$route.query.id).offsetTop;
        window.scrollTo(0, x);
      });
    }

    this.pc();
    this.phone();
  },
};
</script>
<style lang="less" scoped>
.gq-home-recommend-box {
  text-align: center;
  color: #222222;
  .gq-home-recommend-title-box {
    margin-top: 70px;
  }
  .gq-home-recommend-title {
    font-size: 56px;
    font-family: Helvetica;
    font-weight: bold;
  }
  .gq-home-recommend-introduce {
    font-size: 15px;
    margin-top: 23px;
  }
}

.gq-home-phone-picture-box {
  display: none;
}
.gq-home-picture-phone-box {
  display: none;
}
.gq-home-picture-box {
  margin-top: 59px;
  padding-left: 185px;
  padding-right: 185px;

  .gq-home-picture-flex {
    height: 790px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .gq-home-picture-child {
    width: 525px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  img {
    height: 248px;
  }
  .gq-home-picture-img-item img {
    width: 525px;
  }
  .gq-home-picture-img-half img {
    width: 254px;
  }
  .gq-home-picture-content {
    position: relative;
    .gq-home-picture-contentBox {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      cursor: pointer;
      display: none;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.4);
      .gq-home-picture-content-title {
        font-size: 27px;
        padding-top: 30px;
        padding-left: 30px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
  .gq-home-picture-content:hover .gq-home-picture-contentBox {
    display: block;
  }
  .gq-home-picture-img-bigitem img {
    width: 525px;
    height: 525px;
  }
}
.gq-home-partner-phone-box {
  display: none;
}
.gq-home-More-box {
  margin-top: 56px;
  margin-bottom: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  .gq-home-More {
    width: 311px;
    height: 72px;
    line-height: 72px;
    border: 1px solid #7fc9d6;
    opacity: 1;
    border-radius: 4px;
    font-size: 28px;
    font-weight: normal;
    color: #7fc8d5;
    font-style: oblique;
    cursor: pointer;
  }
  .gq-home-More:hover {
    background: linear-gradient(180deg, #62818e 0%, #81ccd9 100%);
    color: #ffffff;
  }
}
.gq-home-partner-box {
  height: 663px;
  padding-top: 50px;
  // margin-top: 50px;
  background: rgb(248, 248, 248);
  .gq-swiper-box {
    display: flex;
    justify-content: center;
    position: relative;
    .swiper-button-next {
      margin-right: 184px;
      background-size: 100% 100%!important;
      width: 124px;
      height: 124px;
      background: url("../../assets/logos/bann-left.png") no-repeat;
      transform:rotate(180deg);
    }
    .swiper-button-prev {
      margin-left: 184px;
       background-size: 100% 100%!important;
      width: 124px;
      height: 124px;
      background: url("../../assets/logos/bann-left.png") no-repeat;
    }
  }
  .gq-swiper-logo-box {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    img {
      width: 39px;
      height: 39px;
      margin-left: 33px;
      margin-right: 33px;
      border-radius: 50%;
    }
    .gq-swiper-logo-div {
      position: relative;
      .gq-swiper-logo-shade {
        width: 39px;
        height: 39px;
        background: #ccc;
        opacity: 0.6;
        position: absolute;
        z-index: 99;
        left: 33px;
        border-radius: 50%;
      }
    }
  }
  .gq-home-partner-title {
    font-size: 56px;
    font-weight: bold;
    line-height: 77px;
    color: #222222;
    margin-top: 77px;
    text-align: center;
    margin-bottom: 88px;
  }
  .cooperation-swiper-container {
    width: 800px;
    height: 230px;
    padding-left: 30px;
    overflow: hidden;
    .flex {
      display: flex;
    }

    .gq-home-img-box {
      width: 230px;
      height: 230px;

      img {
        width: 230px;
        height: 230px;
        border-radius: 50%;
        // overflow: hidden;
      }
    }
    .gq-home-swiper-introduce {
      padding-top: 22px;
      padding-left: 33px;
    }
    .gq-home-swiper-name {
      font-size: 32px;
      font-family: Helvetica-BoldOblique;
      font-weight: bolder;
      font-style: oblique;
      color: #648693;
      span {
        display: block;
        font-family: Helvetica;
        font-weight: normal;
        font-size: 19px;
        color: #b5b5b5;
        display: inline-block;
      }
    }
    .gq-home-swiper-detail {
      font-size: 19px;
      font-family: Helvetica;
      font-weight: 300;
      margin-top: 33px;
      color: #222222;
      width: 470px;
      div {
        margin-top: 20px;
      }
    }
  }
}
.gq-home-information-box {
  // height: 900px;
  .gq-home-information-title {
    font-size: 56px;
    margin-top: 100px;
    font-weight: bold;
    line-height: 77px;
    color: #222222;
    text-align: center;
  }
  .gq-home-information-title-detail {
    font-size: 15px;
    font-weight: 300;
    line-height: 19px;
    // margin-bottom: 23px;
    color: #222222;
    text-align: center;
    display: flex;
    justify-content: center;
    div {
      width: 347px;
      height: 70px;
    }
  }

  .gq-home-information-list-box {
    display: flex;
    justify-content: space-between;
    padding-left: 185px;
    padding-right: 185px;
  }
  .gq-home-information-phone-list-box {
    display: none;
  }
  .gq-home-More-box {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 77px;
    div {
      width: 311px;
      height: 72px;
      border-radius: 3px;
      font-size: 28px;
      font-style: oblique;
      font-weight: normal;
      line-height: 72px;
      color: #7fc8d5;
      border: 1px solid #7fc9d6;
      cursor: pointer;
    }
    div:hover {
      color: #fff;
      background: linear-gradient(180deg, #62818e 0%, #81ccd9 100%);
    }
  }
}
@media only screen and (max-width: 768px) {
  .gq-home-recommend-box {
    text-align: center;
    color: #222222;
    margin-top: 0.8rem;
  }

  .gq-home-recommend-title {
    font-size: 0.8rem !important;
    font-weight: bold;
  }
  .gq-home-recommend-introduce {
    font-size: 0.37rem;
    font-weight: 300;
  }
  .gq-home-picture-box {
    display: none;
  }
  .gq-home-picture-phone-box {
    display: flex;
    justify-content: space-between;
    margin-top: 0.45rem;
    padding-left: 0.27rem;
    width: 9.46rem;
    .gq-home-picture-flex {
      display: flex;
      height: 12rem;
      width: 4.53rem;
      flex-direction: column;
      justify-content: space-between;
      .gq-home-picture-content {
        position: relative;
        .gq-home-picture-name {
          position: absolute;
          bottom: 0px;
          width: 100%;
          height: 0.8rem;
          text-align: center;
          line-height: 0.8rem;
          font-size: 0.32rem;
          font-weight: bold;
          color: #fff;
          background: rgba(0, 0, 0, 0.4);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      .gq-home-picture-img-half {
        img {
          width: 4.54rem;
          height: 2.16rem;
        }
        width: 4.54rem;
        height: 2.16rem;
      }
      .gq-home-picture-img-item {
        img {
          height: 4.53rem;
          width: 4.53rem;
        }
        height: 4.53rem;
        width: 4.53rem;
      }
    }
  }

  .gq-home-More-box {
    margin-top: 0.8rem;
    .gq-home-More {
      width: 5.6rem;
      height: 1.33rem;
      line-height: 1.33rem;
      border: 1px solid #7fc9d6;
      border-radius: 0.1rem;
    }
  }
  .gq-home-partner-box {
    display: none;
  }
  .gq-home-partner-phone-box {
    display: block;
    height: 12.8rem;
    background: rgb(248, 248, 248);
    // width: 10rem;
    // padding-left: 0.27rem;
    // padding-right: 0.27rem;
    padding-top: 0.8rem;

    .gq-home-partner-title {
      height: 0.96rem;
      font-size: 0.8rem;
      font-weight: bold;
      color: #222222;
      text-align: center;
    }
    .gq-swiper-logo-box {
      margin-top: 0.8rem;
      height: 0.6rem;
      display: flex;

      justify-content: center;
      .gq-swiper-logo-div {
        position: relative;
        padding-left: 0.33rem;
        padding-right: 0.33rem;
        border-radius: 50%;
        width: 0.6rem;
        height: 0.6rem;
        overflow: hidden;
      }
      img {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        position: absolute;
        top: 0px;
      }
      .gq-swiper-logo-shade {
        width: 0.6rem;
        height: 0.6rem;
        background: #ccc;
        opacity: 0.6;
        position: absolute;
        z-index: 99;
        border-radius: 50%;
      }
    }
    .cooperation-swiper-container {
      height: 8.38rem;
      margin-top: 0.69rem;
      position: relative;
      text-align: center;

      .flex {
        display: flex;
        flex-direction: column;
      }
      .gq-home-img-box {
        height: 4rem;
        width: 10rem;

        img {
          height: 4rem;
          width: 4rem;
          border-radius: 50%;
        }
      }
      .gq-home-swiper-name {
        font-size: 0.48rem;
        margin-top: 0.35rem;
        font-weight: bold;
        color: #648693;
        font-style: oblique;
      }
      .gq-home-swiper-jieshao {
        font-size: 0.32rem;
        font-weight: normal;
        font-style: oblique;
        line-height: 0.67rem;
        color: #b5b5b5;
      }
      .gq-home-swiper-detail {
        width: 9.47rem;
        font-size: 0.37rem;
        font-family: Helvetica;
        font-weight: 300;
        line-height: 0.43rem;
        color: #222222;
        margin-left: 0.33rem;
        padding-bottom: 0.8rem;
      }
    }
    .swiper-button-prev {
      width: 0.6rem;
      height: 1.16rem;
      top: 2rem;
    }
    .swiper-button-next {
      width: 0.6rem;
      height: 1.16rem;
      top: 2rem;
    }
  }
  .gq-home-information-box {
    // height: 1110px;
    .gq-home-information-title {
      font-size: 0.8rem;
      font-weight: bold;
      color: #222222;
      margin-top: 0rem;
    }
    .gq-home-information-list-box {
      display: none;
    }
    .gq-home-information-title-detail {
      font-size: 0.32rem;
      font-weight: 300;
      color: #222222;
      div {
        border-bottom: 0rem;
        width: 10rem;
        height: auto;
      }
    }
    .gq-home-information-phone-list-box {
      // padding-top: 0.69rem;
      padding-left: 0.27rem;
      padding-right: 0.27rem;
      display: block;
      background: rgb(248, 248, 248);
      // width: 9.35rem;
    }
    .gq-home-More-box {
      display: flex;
      justify-content: center;
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 1.71rem;
      // padding-bottom: 2rem;
      div {
        width: 5.6rem;
        height: 1.33rem;
        border: 0.05rem solid #7fc9d6;
        border-radius: 0.1rem;
        font-size: 0.51rem;
        font-style: oblique;
        font-weight: normal;
        line-height: 1.33rem;
        color: #7fc8d5;
        background: #fff;
      }
    }
  }
}
</style>