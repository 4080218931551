<template>
  <div id="app">
    <div v-show="navShow" class="gq-nav">
      <gqNav></gqNav>
      <gqswiper v-if="page.Home" />
      <div class="news-top-img-box" v-show="page.news">
        <img src="@/assets/imgs/swiper.jpg" alt="" />
        <div class="title-box">
          <div class="titleA">
            University graduates <br />
            go to the society
          </div>
          <div class="titleB">University graduates go to the society</div>
        </div>
      </div>
    </div>
    <div v-show="!navShow" class="pl-nav">
      <wplNav></wplNav>
    </div>
    <!-- <keep-alive> -->
      <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import gqNav from "@/components/gq-nav.vue";
import wplNav from "@/components/wpl-nav.vue";
import gqswiper from "@/components/gq-swiper.vue";
export default {
  components: {
    gqNav,
    wplNav,
    gqswiper
  },
  watch: {
    $route(to, from) {
      for (let i in this.page) {
        this.page[i] = false;
      }
      if (
        to.name == "Home" ||
        to.name == "CooperativeProjects" ||
        to.name == "detail" ||
        to.name == "news" ||
        to.name == 'StudentInsurance'
      ) {
        this.page[to.name] = true;

        this.navShow = true;
      } else {
        this.navShow = false;
      }
    },
  },
  data() {
    return {
      navShow: true,
      newsShow: false,
      activeIndex: "1",
      page: {
        Home: true,
        news: false,
      },
    };
  },
  methods: {
    tonav() {},
  },
};
</script>


<style lang="less">
#app {
  min-width: 1440px;
  width: 100%;
}

.news-top-img-box {
  width: 100%;
  height: 700px;
  position: relative;
  img {
    width: 100%;
    height: 700px;
  }
  .title-box {
    left: 400px;
    top: 52px;
    position: absolute;
    .titleA {
      font-size: 90px;
      font-weight: bold;
      color: #ffffff;
    }
    .titleB {
      font-size: 24px;
      font-weight: normal;
      color: #ffffff;
      margin-top: 30px;
    }
  }
}
@media only screen and (max-width: 768px) {
  #app {
    width: 10rem;
    min-width: 10rem;
    overflow: hidden;
  }
  .view-body {
    width: 10rem;
    margin: 0;
    overflow: hidden;
  }
  .gq-nav {
    display: block !important;
    width: 10rem;
  }
  .pl-nav {
    width: 10rem;
    display: none;
  }
  .news-top-img-box {
    width: 10rem;
    height: 6.16rem;
    position: relative;
    overflow: hidden;
    img {
      width: 10rem;
      height: 6.16rem;
    }
    .title-box {
      display: none;
    }
  }
}
body {
  overflow-x: hidden;
}
</style>
