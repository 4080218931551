<template>
  <div class="gq-information-phone-box">
    <div class="gq-information-phone-flex">
      <div class="gq-information-phone-img-box">
        <img :src="inforData.infoChart" alt="" />
      </div>
      <div class="gq-information-phone-title-box">
        <div class="gq-information-phone-label">
          {{ inforData.infoTitle }}
        </div>
        <div class="gq-information-phone-btn-box">
          <div
            class="gq-information-phone-btn"
            v-for="(item, i) in inforData.infoLabel"
            :key="i"
            :class="i != 0 ? 'phone-left' : ''"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="gq-information-phone-txt" v-html="inforData.infoBrief"></div>
  </div>
</template>
<script>
export default {
  props: {
    inforData: {
      type: Object,
    },
  },
  mounted() {
    if (!Array.isArray(this.inforData.infoLabel)) {
      this.inforData.infoLabel = this.inforData.infoLabel
        ? this.inforData.infoLabel.split(",")
        : [];
    }
  },
};
</script>
<style lang="less" scoped>
.gq-information-phone-box {
  margin-top: 1rem;
  padding-bottom: 0.4rem;
  background: #fff;
}
.gq-information-phone-flex {
  display: flex;
  justify-content: space-between;
  .phone-left {
    margin-left: 0.27rem;
  }
  .gq-information-phone-img-box {
    width: 4.75rem;
    height: 4.75rem;
    img {
      width: 4.75rem;
      height: 4.75rem;
    }
  }
  .gq-information-phone-title-box {
    width: 4.75rem;
    height: 4.25rem;
    padding-top: 0.5rem;
    padding-left: 0.3rem;
    // padding-left: 0.2rem;
    .gq-information-phone-label {
      font-weight: bold;
      //   line-height: 0.48rem;
      color: #222222;
      font-size: 0.48rem;
    }
    .gq-information-phone-btn-box {
      display: flex;
      margin-top: 0.2rem;
      .gq-information-phone-btn {
        width: 1.55rem;
        height: 0.53rem;
        background: #ffffff;
        border: 0.05rem solid #ff9100;
        font-size: 0.37rem;
        text-align: center;
        font-weight: 300;
        line-height: 0.53rem;
        color: #ff9100;
      }
    }
  }
}
.gq-information-phone-txt {
  padding-top: 0.3rem;
  font-size: 0.37rem;
  font-weight: 300;
  color: #888888;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>