import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true
    }
  }, {
    path: '/news',
    name: "news",
    component: () => import('@/views/news'),
    meta: {
      keepAlive: true
    }

  }, {
    path: "/detail",
    name: "detail",
    component: () => import('@/views/newsDetail')
  }, {
    path: "/CooperativeProjects",
    name: "CooperativeProjects",
    component: () => import('@/views/project'),
    meta: {
      keepAlive: false
    }
  }, {
    path: "/projectDetail",
    name: "projectDetail",
    component: () => import('@/views/projectDetail')
  }, {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import('@/views/aboutUs')
  }, {
    path: "/servicePage",
    name: "servicePage",
    component: () => import('@/views/servicePage')
  },
  {  // wp add 2022-10-11 添加一个导航，实现Iframe三方网站表单提交
    path: "/StudentInsurance",
    name: "StudentInsurance",
    component: () => import('@/views/StudentInsurance')
  }
]

const router = new VueRouter({
  routes,
  mode:"history"
})

router.beforeEach((to, from, next) => {
  let arr = router.options.routes
  let newArr = arr.filter(item => { return item.name == to.name });
  if (newArr.length) {
    next()
  } else {
    next('/')
  }
});
export default router
